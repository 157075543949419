import { Box, Text, Card, Loader, MarketingPageLayout, MarketingPageLayoutContent, Page, WixDesignSystemProvider, Image, Layout, FeatureList, Button } from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import VacationImage from './vacation.png';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}


function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=92c0b753-6896-4284-8dce-6cd73ee81f65&redirectUrl=https://certifiedcode.wixsite.com/vacation/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
    <Card>
      <MarketingPageLayout
        removeImageHorizontalPadding
        removeImageVerticalPadding
        content={
          <Box height="840px" verticalAlign="middle">
            <MarketingPageLayoutContent
              title="Put your site into Vacation mode"
              content="When you’re on vacation, you can put your site into Vacation mode. This will display a message to your visitors that you’re away and will be back soon."
              actions={<Box direction="horizontal" gap="SP1"><Button onClick={() => { window.Intercom("showArticle", 8162011) }}>See how</Button><Button skin='inverted' as='a' href='https://bridget.reviews.certifiedcode.us/92c0b753-6896-4284-8dce-6cd73ee81f65' target='_blank'>Rate Vacation 5 stars</Button></Box>}
            />
          </Box>
        }
        image={<Image borderRadius={0} src={VacationImage} />}
      />
    </Card>
  </WixDesignSystemProvider >
}

export default App;
